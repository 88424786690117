import {createApp} from "vue"
import i18n from "./i18n"
import {createVuestic} from "vuestic-ui"
import {createGtm} from "@gtm-support/vue-gtm"

import stores from "./stores"
import router from "./router"
import vuesticGlobalConfig from "./services/vuestic-ui/global-config"
import App from "./App.vue"
import {KinesisContainer, KinesisElement, KinesisScroll, KinesisDistance, KinesisAudio} from "vue-kinesis"

const app = createApp(App)

app.use(stores)
app.use(router)
app.use(i18n)
app.component("kinesis-container", KinesisContainer)
app.component("kinesis-element", KinesisElement)
app.component("kinesis-scroll", KinesisScroll)
app.component("kinesis-distance", KinesisDistance)
app.component("kinesis-audio", KinesisAudio)

app.use(createVuestic({config: vuesticGlobalConfig}))


if (import.meta.env.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: import.meta.env.VITE_APP_GTM_KEY,
      debug: false,
      vueRouter: router
    })
  )
}


app.mount("#app")
