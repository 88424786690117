<script setup>
import {computed, onMounted, ref, onUnmounted, watch} from "vue"

const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    type: String,
    default: "2xl"
  },
  closeable: {
    type: Boolean,
    default: true
  },
  deleteButton: {
    type: Boolean,
    default: false
  },
  permissionToDelete: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(["close", "delete"])
const modal = ref(null)
watch(
  () => props.show,
  () => {
    if (props.show) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = null
    }
  }
)

const close = () => {
  if (props.closeable) {
    emit("close")
  }
}

const closeOnEscape = (e) => {
  if (e.key === "Escape" && props.show) {
    close()
  }
}

onMounted(() => document.addEventListener("keydown", closeOnEscape))

onUnmounted(() => {
  document.removeEventListener("keydown", closeOnEscape)
  document.body.style.overflow = null
})

const maxWidthClass = computed(() => {
  return {
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    "2xl": "sm:max-w-2xl",
    "3xl": "sm:max-w-3xl",
    full: "sm:max-w-full"
  }[props.maxWidth]
})

</script>

<template>
  <Teleport to="body">
    <Transition leave-active-class="duration-200">
      <div v-show="show" class="fixed inset-0 overflow-y-auto px-4 py-6 sm:px-0 z-50 flex items-stretch bg-black/30" scroll-region>
        <Transition
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div v-show="show" class="fixed inset-0 transform transition-all" @click="close">
            <div class="absolute inset-0 opacity-75" />
          </div>
        </Transition>

        <Transition
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0 translate-y-0 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-0 sm:translate-y-0 sm:scale-95"
        >
          <div
            v-show="show"
            ref="modal"
            id="modal"
            class="bg-white dark:bg-slate-600 mt-9 rounded-2xl shadow-xl transform transition-all ease-in-out duration-300 self-center sm:w-auto sm:mx-auto"
            :class="maxWidthClass"
          >

              <slot v-if="show" />

          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>
<style scoped>
 #modal{
  scale: 1;
  @starting-style{
    scale: 0;
  }
 }
</style>
