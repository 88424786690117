<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaAccordion v-model="value" multiple>
      <div class="py-4 px-2">
        <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="index">
          <template #header="{value: isCollapsed}">
            <VaSidebarItem
              :to="route.children ? undefined : route.name == 'reserve' ? route.path : {name: route.name, params: route.params}"
              :active="routeHasActiveChild(route)"
              :active-color="activeColor"
              :text-color="textColor(route)"
              :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${route.name == 'reserve' ? route.displayName : t(route.displayName)}`"
              role="button"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-4">
                <VaIcon
                  v-if="route.meta.icon"
                  aria-hidden="true"
                  :name="route.meta.icon"
                  size="20px"
                  :color="iconColor(route)"
                />
                <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                  <span :class="{
                    'underline': isCollapsed
                  }">{{route.name == 'reserve' ? route.displayName : t(route.displayName) }}</span>
                  <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </template>
          <template #body>
            <div v-for="(childRoute, index2) in route.children" :key="index2">
              <VaSidebarItem
                :to="route.name == 'reserve' ? childRoute.path : {name: childRoute.name}"
                :active="isActiveChildRoute(childRoute)"
                :active-color="activeColor"
                :text-color="textColor(childRoute)"
                :aria-label="`Visit ${t(route.name == 'reserve' ? childRoute.displayName : t(childRoute.displayName))}`"
                hover-opacity="0.10"
              >
                <VaSidebarItemContent class="py-3 pr-2 pl-11">
                  <VaSidebarItemTitle class="leading-5 font-semibold">
                    {{  route.name =='reserve' ? t(route.name == 'reserve')  : childRoute.displayName }}
                  </VaSidebarItemTitle>
                </VaSidebarItemContent>
              </VaSidebarItem>
            </div>
          </template>
        </VaCollapse>
      </div>
    </VaAccordion>
  </VaSidebar>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onBeforeMount, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useColors } from 'vuestic-ui';
import { usePathsStore } from '../../stores/paths';
import { fetchPaths } from '../../api/paths';
import navigationRoutes, { type INavigationRoute } from './NavigationRoutes';

export default defineComponent({
  name: 'Sidebar',
  props: {
    visible: { type: Boolean, default: true },
    mobile: { type: Boolean, default: false }
  },
  emits: ['update:visible'],

  setup: (props, { emit }) => {
    const { getColor, colorToRgba } = useColors();
    const route = useRoute();
    const { t } = useI18n();

    const value = ref<boolean[]>([]);
    let trailRoutes: any = [];

    const writableVisible = computed({
      get: () => props.visible,
      set: (v: boolean) => emit('update:visible', v)
    });

    const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name;

    const routeHasActiveChild = (section: INavigationRoute) => {
      if (!section.children) {
        return route.path.endsWith(`${section.name}`);
      }
      return section.children.some(({ name }) => route.path.endsWith(`${name}`));
    };

    const setActiveExpand = async () => {
      value.value = navigationRoutes.routes.map(route => routeHasActiveChild(route));
      await nextTick();
    };

    const sidebarWidth = computed(() => (props.mobile ? '100vw' : '180px'));
    const color = computed(() => getColor('background-secondary'));
    const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1));

    const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary');
    const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary');
    const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down');

    onBeforeMount(async () => {
      await setActiveExpand();  // Asegúrate de que se llame después de actualizar las rutas
    });

    return {
      writableVisible,
      sidebarWidth,
      value,
      color,
      activeColor,
      navigationRoutes,
      routeHasActiveChild,
      isActiveChildRoute,
      t,
      iconColor,
      textColor,
      arrowDirection
    };
  }
});
</script>
