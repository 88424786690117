<!-- eslint-disable prettier/prettier -->
<template>
  <VaLayout  class="h-screen bg-[var(--va-background-secondary)] md:overflow-hidden">
    <template #content>
      <div class="p-4 body">
        <main class="h-screen flex items-center justify-center mx-auto max-w-full md:max-w-[650px]">
          <div class="flex flex-col items-center justify-center h-screen md:h-fit">
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import {useBreakpoint} from "vuestic-ui"

const breakpoint = useBreakpoint()
</script>
<style scoped>
 .body {
  background-image: url('/img/bg-agencias-2.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}
</style>
