{
  "attributes": {
    "Empresa": "Empresa",
    "Licencia": "Licencia",
    "Documento": "Documento",
    "Email": "Email",
    "Teléfono": "Teléfono",
    "País": "País",
    "Ciudad": "Ciudad",
    "Provincia": "Provincia",
    "Código Postal": "Código Postal",
    "Dirección": "Dirección",
    "Persona de Contacto": "Persona de Contacto",
    "Nombre": "Nombre",
    "Apellidos": "Apellidos",
    "Password": "Contraseña",
    "Idioma": "Idioma",
    "contact_person": "Persona de contacto"
  },
  "actions": {
    "Enviar": "Enviar",
    "Cancelar": "Cancelar",
    "Guardar": "Guardar",
    "Reservar": "Reservar",
    "Solicitar modificación": "Solicitar modificación",
    "Contactar con soporte": "Contactar con soporte",
    "Acceder": "Acceder",
    "Solicitar acceso": "Solicitar acceso",
    "Olvidaste tu contraseña?": "Olvidaste tu contraseña?",
    "Mantenme logueado en este dispositivo": "Mantenme logueado en este dispositivo",
    "Iniciar sesión": "Iniciar sesión",
    "previous": "Anterior",
    "next": "Siguiente",
    "save-budget": "Guardar presupuesto",
    "reserve": "Reservar",
    "edit": "Editar",
    "seleccionar": "Seleccionar",
    "accept": "Aceptar",
    "show-password": "Mostrar contraseña",
    "show": "Ver",
    "search-by-id-or-customer": "Buscar por ID o cliente"
  },
  "404": {
    "title": "Esta página se ha ido a pescar",
    "text": "Si crees que esto no es correcto, por favor envíanos un mensaje a ",
    "back_button": "Volver al tablero"
  },
  "language": {
    "portuguese": "Portugués",
    "english": "Inglés",
    "spanish": "Español",
    "deutch": "Alemán",
    "italian": "Italiano",
    "french": "Francés"
  },
  "menu": {
    "home": "Inicio",
    "auth": "Autenticación",
    "buttons": "Botones",
    "timelines": "Líneas de tiempo",
    "budgets": "Presupuestos",
    "reservations": "Reservas",
    "preferences": "Preferencias",
    "payments": "Pagos",
    "settings": "Configuración",
    "login-singup": "Iniciar sesión/Registrarse",
    "404": "Páginas 404",
    "faq": "FAQ",
    "customers": "Clientes",
    "paths": "Caminos",
    "trails": "Rutas",
    "prices": "Precios",
    "invoices": "Facturas"
  },
  "messages": {
    "all": "Ver todos los mensajes",
    "new": "Nuevos mensajes de {name}",
    "mark_as_read": "Marcar como leído",
    "mark_as_unread": "Marcar como no leído",
    "answer": "Responder",
    "answered": "Respondido",
    "send-answer": "Enviar respuesta",
    "Ya tienes acceso a la gestión de tu agencia?": "Ya tienes acceso a la gestión de tu agencia?",
    "No tienes acceso a la gestión de tu agencia?": "No tienes acceso a la gestión de tu agencia?",
    "request-sent": "Se ha enviado la solicitud, en breve recibirás una confirmación por correo electrónico",
    "error-sending-request": "Ha ocurrido un error",
    "new-user-request": "Nueva solicitud de usuario",
    "required-field": "Campo requerido",
    "privacy-policy": "He leído y acepto la política de privacidad"
  },
  "notifications": {
    "all": "Ver todas las notificaciones",
    "mark_as_read": "Marcar como leída",
    "sentMessage": "{name} te envió un mensaje",
    "uploadedZip": "{name} subió un archivo Zip con {type}",
    "startedTopic": "{name} inició un nuevo tema"
  },
  "user": {
    "agency": "Agencia",
    "language": "Cambiar Idioma",
    "logout": "Cerrar sesión",
    "profile": "Mi Perfil",
    "settings": "Configuración",
    "faq": "Preguntas Frecuentes",
    "help": "?",
    "helpAndSupport": "Ayuda & Soporte",
    "account": "Cuenta",
    "explore": "Explorar",
    "data": "Datos de usuario",
    "password": {
      "old": "Contraseña anterior",
      "new": "Nueva contraseña",
      "confirm": "Confirmar contraseña",
      "rules": {
        "length": "La contraseña debe tener al menos 8 caracteres",
        "minSpecialChars": "La contraseña debe tener al menos un carácter especial"
      }
    },
    "security": "Seguridad",
    "last_updated": "Última actualización"
  },
  "support": {
    "send-message": "Contactar con soporte",
    "message-sent": "Mensaje de soporte enviado"
  },
  "message": "Mensaje",
  "helpAndSupport": "Ayuda y Soporte",
  "search": {
    "placeholder": "Buscar..."
  },
  "reservations": {
    "reservation": "Reserva",
    "customer": "Cliente",
    "rooms": "Habitaciones",
    "services": "Servicios",
    "payments": "Pagos",
    "people-to": "personas",
    "total": "Total",
    "payment-method": "Método de pago",
    "payd": "Pagada",
    "modification-request": "Solicitud de modificación",
    "request-modification": "Solicitar modificación",
    "date-start": "Fecha de inicio",
    "from-date": "desde el",
    "to-date": "hasta el",
    "send-modification": "Enviar modificación",
    "modification-sent": "Modificación enviada",
    "set-customer": "Asignar cliente",
    "card-payment": "Pago con tarjeta",
    "transfer-payment": "Pago por transferencia",
    "no-specified": "No especificado",
    "pay": "Pagar",
    "price-per-person": "€/persona",
    "trail": "Ruta",
    "select-trail": "Seleccione la ruta",
    "unpaid": "No pagado"
  },
  "forms": {
    "Datos de Agencia": "Datos de Agencia",
    "Datos de Contacto": "Datos de Contacto",
    "reset-password": "Restablecer contraseña",
    "invalid": "Revisa los campos marcados para continuar.",
    "new-password": "Nueva contraseña",
    "confirm-password": "Confirmar contraseña",
    "back_to_login": "Volver al login"
  },
  "trails": {
    "noches": "noches",
    "trail": "Ruta",
    "stages": "Etapas",
    "single_room": "Individual",
    "double_room": "Doble",
    "triple_room": "Triple",
    "breakfasts": "Desayunos",
    "dinners": "Cenas",
    "bags_transport": "Transporte mochilas",
    "bicycles": "Bicicletas",
    "prices": {
      "people-to": "personas",
      "download-csv": "Descargar CSV"
    }
  },
  "prices": {
    "desde": "desde",
    "people-to": "persona"
  },
  "titles": {
    "options": "Opciones"
  },
  "customers": {
    "customers": "Clientes",
    "new": "Nuevo cliente",
    "edit": "Editar cliente",
    "updated": "Cliente actualizado",
    "error": "Error al actualizar el cliente"
  },
  "password": {
    "new": {
      "rules": {
        "length": "La contraseña debe tener al menos 8 caracteres",
        "minSpecialChars": "La contraseña debe tener al menos un carácter especial",
        "differentFromOld": "La nueva contraseña debe ser diferente de la contraseña anterior"
      },
      "confirm": {
        "required": "La confirmación de la contraseña es requerida",
        "match": "Las contraseñas coinciden"
      },
      "old": {
        "required": "La contraseña anterior es requerida"
      },
      "new": "Nueva contraseña",
      "required": "La contraseña es requerida"
    },
    "forgot": {
      "question": "¿Olvidaste tu contraseña?",
      "success": "Te hemos enviado un correo electrónico con un enlace para restablecer tu contraseña",
      "email": "Email",
      "action": "Enviar",
      "description": "Introduce tu dirección de correo electrónico y te enviaremos un enlace para restablecer tu contraseña"
    },
    "token": {
      "invalid": "El token es inválido",
      "expired_title": "El token ha expirado",
      "expired": "El token ha expirado, por favor solicita uno nuevo"
    },
    "updated": "Contraseña actualizada",
    "mismatch": "Las contraseñas no coinciden"
  },
  "agency": {
    "data": {
      "updated": "Datos de la agencia actualizados"
    }
  },
  "info": {
    "loading": "Cargando...",
    "no-results": "No se han encontrado resultados"
  },
  "customer": {
    "name_and_last_name": "Nombre y apellidos",
    "email": "Email",
    "phone": "Teléfono",
    "select-country": "Seleccione el país",
    "no-email": "No se ha encontrado ningún correo electrónico",
    "search": "Buscar clientes"
  },
  "home": {
    "youtube-message": "Últimos videos en nuestro canal de",
    "youtube-suscribe": "Suscríbete a nuestro canal para recibir notificaciones de nuevos videos",
    "download-guide": "DESCARGAR GUÍA"
  },
  "urls":{
    "privacy-policy-url" : "/politica-de-privacidad/"
  },
  "labels":{
    "privacy-policy": "Política de privacidad"
  },
  "auth":{
    "login_error": "Credenciales incorrectas",
    "session_expired": "Tu sesión ha expirado, por favor vuelve a iniciar sesión",
    "invalid_credentials": "Credenciales inválidas",
    "password-reset-succesfully": "Contraseña restablecida con éxito"
  }
}
