import {createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw} from "vue-router"

import AuthLayout from "../layouts/AuthLayout.vue"
import AppLayout from "../layouts/AppLayout.vue"
import {useAuthStore} from "../stores/auth"
import axios from "axios"
const routes: Array<RouteRecordRaw> = [
  {
    name: "admin",
    path: "/",
    component: AppLayout,
    children: [
      {
        name: "home",
        path: "home",
        component: () => import("../pages/admin/Home.vue")
      },
      {
        name: "budgets",
        path: "budgets",
        component: () => import("../pages/admin/dashboard/IndexBudgets.vue")
      },
      {
        name: "reservations",
        path: "reservations",
        component: () => import("../pages/admin/dashboard/IndexReservations.vue")
      },
      {
        name: "settings",
        path: "settings",
        component: () => import("../pages/settings/Settings.vue")
      },
      {
        name: "preferences",
        path: "preferences",
        component: () => import("../pages/preferences/Preferences.vue")
      },
      {
        name: "customers",
        path: "customers",
        component: () => import("../pages/customers/CustomersPage.vue")
      },
      {
        name: "prices",
        path: "prices",
        component: () => import("../pages/prices/Index.vue")
      },
      {
        name: "invoices",
        path: "invoices",
        component: () => import("../pages/invoices/Index.vue")
      },
      {
        name: "trails",
        path: "trails",
        component: () => import("../pages/trails/Index.vue")
      },
      {
        name: "reserve",
        path: "reserve/:pathId/:trailId",
        component: () => import("../pages/reservations/widgets/ReservationForm.vue")
      },
      {
        name: "faq",
        path: "/faq",
        component: () => import("../pages/faq/FaqPage.vue")
      }
    ]
  },
  {
    path: "/auth",
    component: AuthLayout,
    children: [
      {
        name: "login",
        path: "login",
        component: () => import("../pages/auth/Login.vue")
      },
      {
        name: "signup",
        path: "signup",
        component: () => import("../pages/auth/Signup.vue")
      },
      {
        name: "recover-password",
        path: "recover-password",
        component: () => import("../pages/auth/RecoverPassword.vue")
      },
      {
        name: "recover-password-email",
        path: "recover-password-email",
        component: () => import("../pages/auth/CheckTheEmail.vue")
      },
      {
        name: "reset-password",
        path: "reset-password",
        component: () => import("../pages/auth/ResetPassword.vue")
      },
      {
        path: "",
        redirect: {name: "login"}
      },
      {
        path: "/logout",
        name: "logout",
        redirect: {name: "login"}
      }
    ]
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../pages/404.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: {name: "home"}
  }
]

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return {el: to.hash, behavior: "smooth"}
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();

  // Sincronizar el store con localStorage al inicio
  const storedToken = localStorage.getItem("private_token");
  const storedUser = localStorage.getItem("authUser");

  // Asegurarnos que ambos existen y son válidos
  const isAuthenticated = !!(storedToken && storedUser && authStore.authUser);

  // Si el store no está sincronizado pero hay datos en localStorage
  if (!authStore.authUser && storedUser && storedToken) {
    try {
      authStore.authUser = JSON.parse(storedUser);
    } catch (error) {
      // Si hay error al parsear, limpiamos todo
      localStorage.removeItem("private_token");
      localStorage.removeItem("authUser");
      return next("/auth/login");
    }
  }

  const publicRoutes = ["/auth/login", "/auth/recover-password", "/auth/reset-password", "/auth/signup"];
  const isPublicRoute = publicRoutes.some((route) => to.path.startsWith(route));

  // Debug log
  console.log('Navigation guard:', {
    to: to.path,
    from: from.path,
    isAuthenticated,
    isPublicRoute,
    hasToken: !!storedToken,
    hasUser: !!storedUser,
    hasStoreUser: !!authStore.authUser
  });

  // Redirigir a login si no está autenticado y no es ruta pública
  if (!isAuthenticated && !isPublicRoute) {
    authStore.lastVisited = to.fullPath;
    localStorage.setItem("lastVisitedRoute", to.fullPath);
    return next("/auth/login");
  }

  // Si está autenticado y trata de acceder a una ruta pública
  if (isAuthenticated && isPublicRoute) {
    const savedPath = localStorage.getItem("lastVisitedRoute");
    if (savedPath && savedPath !== '/auth/login') {
      localStorage.removeItem("lastVisitedRoute");
      return next(savedPath);
    }
    return next("/home");
  }

  // Actualizar lastVisitedRoute para rutas autenticadas no públicas
  if (isAuthenticated && !isPublicRoute && to.path !== '/') {
    localStorage.setItem("lastVisitedRoute", to.fullPath);
    authStore.lastVisited = to.fullPath;
  }

  return next();
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401 || error.response?.data?.message === "Unauthenticated.") {
      const authStore = useAuthStore();

      // Limpiar el store y localStorage
      localStorage.removeItem("lastVisitedRoute");
      localStorage.removeItem("authUser");
      localStorage.removeItem("private_token");
      authStore.lastVisited = "";
      authStore.authUser = null;
      // Redirigir al login
      router.push('/auth/login');
    }

    return Promise.reject(error);
  }
);

export default router
