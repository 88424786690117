<template>
  <VaLayout
    :top="{fixed: true, order: 2}"
    :left="{fixed: true, absolute: breakpoints.mdDown, order: 1, overlay: breakpoints.mdDown && !isSidebarMinimized}"
    @leftOverlayClick="isSidebarMinimized = true"
  >
    <template #top>
      <AppNavbar :is-mobile="isMobile" />
    </template>

    <template #left>
      <AppSidebar :minimized="isSidebarMinimized" :animated="!isMobile" :mobile="isMobile"  v-if="route.name != 'reserve'" />
    </template>

    <template #content>
      <main id="app-body">
        <article>
          <router-view v-slot="{ Component }">
            <Transition name="fade">
              <component :is="Component" />
            </Transition>
          </router-view>
        </article>
        <LoadingFrontDrop v-if="auth.loading" />
      </main>
    </template>
  </VaLayout>
</template>

<script setup>
import {onBeforeUnmount, onMounted, ref, computed, defineAsyncComponent} from "vue"
import {storeToRefs} from "pinia"
import {onBeforeRouteUpdate} from "vue-router"
import {useBreakpoint} from "vuestic-ui"
import { useRoute, useRouter } from "vue-router"
import { useAuthStore } from "../stores/auth"

import AppNavbar from "../components/navbar/AppNavbar.vue"
import AppSidebar from "../components/sidebar/AppSidebar.vue"
import {useGlobalStore} from "../stores/global-store"
const LoadingFrontDrop = defineAsyncComponent(() => import("../components/LoadingFrontDrop.vue"))

const auth = useAuthStore()
const GlobalStore = useGlobalStore()
const {isSidebarMinimized} = storeToRefs(GlobalStore)
const breakpoints = useBreakpoint()
const sidebarWidth = ref("16rem")
const sidebarMinimizedWidth = ref(undefined)

const isMobile = ref(false)
const isTablet = ref(false)
const route = useRoute()
const router = useRouter()
const onResize = () => {
  isSidebarMinimized.value = breakpoints.mdDown
  isMobile.value = breakpoints.smDown
  isTablet.value = breakpoints.mdDown
  sidebarMinimizedWidth.value = isMobile.value ? "0" : "4.5rem"
  sidebarWidth.value = isTablet.value ? "100%" : "16rem"
}

onMounted(() => {
  // window.addEventListener("resize", onResize)
  onResize()
})

onBeforeUnmount(() => {
  //window.removeEventListener("resize", onResize)
})

onBeforeRouteUpdate(() => {
  if (breakpoints.mdDown) {
    // Collapse sidebar after route change for Mobile
    isSidebarMinimized.value = true
  }
})

const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)

const onCloseSidebarButtonClick = () => {
  isSidebarMinimized.value = true
}
</script>

<style lang="scss" scoped>
 #app-body {
  background-image: url('/Camino.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  overflow: hidden;
}
// Prevent icon jump on animation
.va-sidebar {
  width: unset !important;
  min-width: unset !important;
}
/* Transición para la entrada (fade-in) */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>
