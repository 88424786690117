import { fetchPaths } from "../../api/paths";
import type { INavigationRoute } from '../../types/navigation_route';


const routes: INavigationRoute[] = [
  {
    name: "home",
    displayName: "menu.home",
    meta: {
      icon: "home"
    }
  },
  {
    name: "trails",
    displayName: "actions.Reservar",
    meta: {
      icon: "route"
    }
  },
  {
    name: "budgets",
    displayName: "menu.budgets",
    meta: {
      icon: "description"
    }
  },
  {
    name: "reservations",
    displayName: "menu.reservations",
    meta: {
      icon: "receipt_long"
    }
  },
  {
    name: "customers",
    displayName: "menu.customers",
    meta: {
      icon: "group"
    }
  },
  {
    name: "prices",
    displayName: "menu.prices",
    meta: {
      icon: "euro"
    }
  },
  {
    name: "invoices",
    displayName: "menu.invoices",
    meta: {
      icon: "receipt_long"
    }
  }
];

export default {
  root: {
    name: "/",
    displayName: "navigationRoutes.home"
  },
  routes: routes
};
