import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useToast } from "vuestic-ui";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  welcome,
  checkToken,
  login as getAuth,
  exit,
  updateAgency,
  updatePassword,
  recoverPassword,
  resetPassword,
  fetchAgencyDataFromTbc,
  sendRegistrationRequest,
  fetchPaises
} from "../api/auth";

export const useAuthStore = defineStore("auth", () => {
  const router = useRouter();
  const { t } = useI18n();
  const toast = useToast();

  const authUser = ref(null);
  const language = ref("es");
  const public_token = computed(() => localStorage.getItem("public_token"));
  const private_token = computed(() => localStorage.getItem("private_token"));
  const lastVisited = ref("");
  const loading = ref(false);
  const errorMessage = ref("");

  async function checkAccess() {
    try {
      const response = await welcome();
      localStorage.setItem("public_token", response.data.public_token);

      if (private_token.value) {
        await checkPrivateToken();
      } else if (!router.currentRoute.value.path.includes("auth")) {
        router.push("/auth/login");
      }
    } catch (error) {
      console.error("Error checking access:", error);
    }
  }

  async function checkPrivateToken() {
    try {
      const response = await checkToken(private_token.value);
      if (!response.data.user) {
        handleInvalidToken();
      } else {
        authUser.value = response.data.user;
        router.push(lastVisited.value || "/home");
      }
    } catch (error) {
      console.error("Error validating private token:", error);
      handleInvalidToken();
    }
  }

  function handleInvalidToken() {
    localStorage.removeItem("private_token");
    localStorage.removeItem("authUser");
    authUser.value = null;
    toast.init({
      message: t("auth.session_expired"),
      duration: 3000,
      position: "top-right",
      color: "error",
    });
    router.push("/auth/login");
  }

  async function login(user) {
    try {
      loading.value = true;
      const response = await getAuth(user);

      if (response.data.user) {
        // Solo maneja la autenticación
        authUser.value = response.data.user;
        localStorage.setItem("private_token", response.data.access_token);
        localStorage.setItem("authUser", JSON.stringify(response.data.user));
        return true;
      } else {
        showErrorToast(t("auth.invalid_credentials"));
        return false;
      }
    } catch (error) {
      showErrorToast(t("auth.login_error"));
      console.error("Error during login:", error);
      return false;
    } finally {
      loading.value = false;
    }
  }

  async function handleResetPassword(data) {
    const response = await resetPassword(data.email, data.password,data.password_confirmation, data.token);
    return response;
  }

  function logout() {
    return exit(private_token.value)
      .then(() => {
        // Limpiamos el store y localStorage
        localStorage.removeItem("private_token");
        localStorage.removeItem("authUser");
        authUser.value = null;
        private_token.value = null;
        return true;
      })
      .catch((error) => {
        console.error("Error during logout:", error);
        // Aún así limpiamos todo en caso de error
        localStorage.removeItem("private_token");
        localStorage.removeItem("authUser");
        authUser.value = null;
        private_token.value = null;
        return true;
      });
  }


  function initAuth() {
    const storedUser = localStorage.getItem("authUser");
    if (storedUser) {
      try {
        authUser.value = JSON.parse(storedUser);
      } catch {
        localStorage.removeItem("authUser");
      }
    }

    if (private_token.value) {
      checkPrivateToken();
    }
  }

  async function changeUserData(user) {
    try {
      return await updateAgency(user);
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  }

  async function changePassword(oldPass, pass) {
    try {
      const response = await updatePassword(authUser.value, { old_password: oldPass, password: pass });
      return response;
    } catch (error) {
      console.error("Error updating password:", error);
    }
  }

  async function register(agency) {
    try {
      loading.value = true;
      return await sendRegistrationRequest(agency);
    } catch (error) {
      console.error("Error during registration:", error);
    } finally {
      loading.value = false;
    }
  }

  async function getUserData() {
    try {
      return await fetchAgencyDataFromTbc(authUser.value.agencia_id);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  async function recoverPasswordRequest(email) {
    try {
      return await recoverPassword(email);
    } catch (error) {
      console.error("Error recovering password:", error);
    }
  }

  async function getPaises() {
    try {
      return await fetchPaises();
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  }

  function showErrorToast(message) {
    toast.init({
      message,
      duration: 3000,
      position: "top-right",
      color: "error",
    });
  }

  return {
    authUser,
    language,
    public_token,
    private_token,
    lastVisited,
    loading,
    errorMessage,
    checkAccess,
    login,
    logout,
    initAuth,
    changeUserData,
    changePassword,
    register,
    getUserData,
    recoverPasswordRequest,
    getPaises,
    handleResetPassword,
  };
});
