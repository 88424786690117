<template>
  <VaNavbar class="app-layout-navbar py-2 px:0 md:px-8">
    <template #left>
      <div class="left">
        <Transition name="icon-fade" mode="out-in">
          <VaIcon
            v-if="route.name != 'reserve'"
            color="#ffffff"
            :name="isSidebarMinimized ? 'menu' : 'close'"
            size="24px"
            style="margin-top: 3px"
            @click="isSidebarMinimized = !isSidebarMinimized"
          />
        </Transition>
        <RouterLink to="/home" aria-label="Visit home page">
          <TBCLogo :height="32" />
        </RouterLink>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import {storeToRefs} from "pinia"
import {useGlobalStore} from "../../stores/global-store"
import AppLayoutNavigation from "../app-layout-navigation/AppLayoutNavigation.vue"
import AppNavbarActions from "./components/AppNavbarActions.vue"
import LanguageSelector from "../LanguageSelector.vue"
import TBCLogo from "../TBCLogo.vue"
import { useRoute } from "vue-router"
defineProps({
  isMobile: {type: Boolean, default: false}
})

const route = useRoute()

const GlobalStore = useGlobalStore()

const {isSidebarMinimized} = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
header {
  background-color: rgb(0, 84, 138) !important;
}
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}

</style>
